// src/components/Listing.js
import React from 'react';

const Listing = ({ title, price, imageUrl, link }) => {
  return (
    <div className="listing">
      <img src={imageUrl} alt={title} style={{width: '150px', height: '150px'}} />
      <h3>{title}</h3>
      <p id="price">Price: {price}</p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <button className="amazon-button">View on Amazon</button>
      </a>
    </div>
  );
};

export default Listing;
