import React from 'react';
import Listing from './Listing';

const Listings = ({ listings }) => {
  return (
    <div className="listings">
      {listings.map((listing, index) => (
        <Listing
          key={index}
          title={listing.title}
          price={listing.price}
          imageUrl={listing.imageUrl}
          link={listing.link}
        />
      ))}
    </div>
  );
};

export default Listings;
