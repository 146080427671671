// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        As an Amazon Associate, I earn from qualifying purchases. This means that if you purchase something through the links on this site, I may receive a small commission at no extra cost to you.
      </p>
    </footer>
  );
};

export default Footer;
