// src/App.js
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Listings from './components/Listings';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  
  // Static data for demonstration
  const [listings] = useState([
    {
      title: "Restaurantware 3.5 Inch x 1.3 Inch Heart Spoons 500 Disposable Mini Plastic Spoons",
      price: "$36.10",
      imageUrl: "https://m.media-amazon.com/images/I/51ja8cd5BmL._AC_SX679_.jpg",
      link: "https://a.co/d/eDWJ7Tx"
    },
    {
      title: "Alienware Aurora R16 Gaming Desktop - Intel Core i9-13900F 32GB DDR5 RAM, 1TB SSD + 1TB HDD, NVIDIA GeForce RTX 4070",
      price: "$1899.99",
      imageUrl: "https://m.media-amazon.com/images/I/71C+ewM2JjL._AC_SX679_.jpg",
      link: "https://a.co/d/4f09efH"
    },
    {
      title: "VTech VM819 Baby Monitor, 2.8” Screen, Night Vision, 2-Way Audio, Temperature Sensor and Lullabies",
      price: "$44.96",
      imageUrl: "https://m.media-amazon.com/images/I/71a1lTuaZnL._SX679_.jpg",
      link: "https://a.co/d/3y6ySOT"
    },
    {
      title: "TP-Link AX1800 WiFi 6 Router V4 (Archer AX21) – Dual Band Wireless Internet Router",
      price: "$74.99",
      imageUrl: "https://m.media-amazon.com/images/I/51NPaIorJiL._AC_SX679_.jpg",
      link: "https://a.co/d/7Oj3Uun"
    },
  ]);
  
  const [recentlistings] = useState([
    {
      title: "Graco Extend2Fit Convertible Baby Car Seat, Rear and Forward Facing",
      price: "$149.99",
      imageUrl: "https://m.media-amazon.com/images/I/714CwhGszrL._SX679_.jpg",
      link: "https://a.co/d/53N5wEa"
    },
    {
      title: "Amazon Fire TV Stick 4K streaming device",
      price: "$29.99",
      imageUrl: "https://m.media-amazon.com/images/I/417H5BZf2LL._AC_SY450_.jpg",
      link: "https://a.co/d/dP6G5gj"
    },
    {
      title: "ESR for iPhone 16 Pro Max Case, Camera Control",
      price: "$12.74",
      imageUrl: "https://m.media-amazon.com/images/I/71LHtYAJe5L._AC_SX679_.jpg",
      link: "https://a.co/d/19EEXDs"
    },
    {
      title: "Acer Nitro V Gaming Laptop | Intel Core i5-13420H Processor",
      price: "$649.99",
      imageUrl: "https://m.media-amazon.com/images/I/71F-Wcriq4L._AC_SX679_.jpg",
      link: "https://a.co/d/gaCylMM"
    }, 
    {
      title: "LEVOIT Air Purifiers for Home Large Room Up to 1800 Ft² in 1 Hr with Washable Filters",
      price: "$159.99",
      imageUrl: "https://m.media-amazon.com/images/I/81zgfQVzAlL._AC_SX466_.jpg",
      link: "https://a.co/d/8q21Vqb"
    }, 
    {
      title: "Sceptre 34-Inch Curved Ultrawide WQHD Monitor 3440 x 1440",
      price: "$216.97",
      imageUrl: "https://m.media-amazon.com/images/I/71sPOWyMwVL._AC_SX679_.jpg",
      link: "https://a.co/d/564tEDD"
    }, 
    {
      title: "Bedsure King Size Sheet Set, Cooling Sheets King, Rayon Derived from Bamboo",
      price: "$51.98",
      imageUrl: "https://m.media-amazon.com/images/I/81u9VOmSn7L._AC_SX679_.jpg",
      link: "https://a.co/d/iOB2uIu"
    }, 
    {
      title: "JBL Vibe Beam - True Wireless JBL Deep Bass Sound Earbuds, Bluetooth 5.2",
      price: "$29.95",
      imageUrl: "https://m.media-amazon.com/images/I/41+1Csr1pSL._AC_SX679_.jpg",
      link: "https://a.co/d/8aWV0dT"
    }, 
    {
      title: "BLUETTI Portable Power Station AC180, 1152Wh LiFePO4 Battery Backup w/ 4 1800W",
      price: "$499.99",
      imageUrl: "https://m.media-amazon.com/images/I/71Lg8i251IL._AC_SX679_.jpg",
      link: "https://a.co/d/6g3EjMj"
    }, 
    {
      title: "Beats Fit Pro - True Wireless Noise Cancelling Earbuds - Apple H1 Headphone Chip",
      price: "$159.00",
      imageUrl: "https://m.media-amazon.com/images/I/5118KKFLhaL._AC_SX522_.jpg",
      link: "https://www.amazon.com/dp/B0CDP42MJV"
    }, 
  ]);

 

  // Fetching data could be done here
  // useEffect(() => {
  //   fetch('API_URL')
  //     .then(response => response.json())
  //     .then(data => setListings(data));
  // }, []);

  return (
    <div className="App">
      <Navbar />
      <h1>Amazon Listings</h1>
      <Listings listings={listings} />
      <br></br>
      <h1>Latest Deals</h1>
      <Listings listings={recentlistings} />
      <Footer />
    </div>
  );
};

export default App;
